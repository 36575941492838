import React, { useContext, Suspense, lazy } from 'react';
import { Route, Routes } from 'react-router-dom';
import './App.css';
import { Layout } from './components/Layout';
import AuthContext from './context/AuthContext';

// Lazy load components
const NotFound = lazy(() => import('./pages/404/NotFound'));
const WarrantyPlans = lazy(() => import('./pages/WarrantyPlans'));
const PlanDetails = lazy(() => import('./pages/WarrantyPlans/PlanDetails'));
const Login = lazy(() => import('./pages/auth/login'));
const ClaimDetail = lazy(() => import('./pages/claim/details'));
const ClaimDetailPub = lazy(() => import('./pages/claim/detailsPub'));
const ClaimList = lazy(() => import('./pages/claim/list'));
const Dashboard = lazy(() => import('./pages/dashboard'));
// const FileClaim = lazy(() => import('./pages/claim/fileClaim'));

function App() {
  const authContext = useContext(AuthContext);
  const isLoggedIn = authContext?.data?.token && authContext?.data?.user ? true : false;

  return (
    <div className='App' style={{ height: '100vh', overflow: 'hidden' }}>
      <Suspense fallback={<div>Loading...</div>}>
        <Routes>
          <Route path='/' element={<Layout withSidebar={isLoggedIn} />}>
            <Route path='/' element={<Dashboard />} />
            <Route path='/claim' element={<ClaimList />} />
            <Route path='/claim/:id' element={isLoggedIn ? <ClaimDetail /> : <ClaimDetailPub />} />
            {/* <Route path='/claim/create' element={<FileClaim />} /> */}
            <Route path='/warrantyplans' element={<WarrantyPlans />} />
            <Route path='/warrantyplans/:id' element={<PlanDetails />} />
            <Route path='*' element={<NotFound />} />
          </Route>
          <Route path='/login' element={<Login />} />
        </Routes>
      </Suspense>
    </div>
  );
}

export default App;
